* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
    scroll-behavior: smooth;
}

body {
    cursor: default;
    overflow-x: hidden;
    background-color: #e9e9e9;
    color: #111;
}

a {
    color: inherit;
    text-decoration: none;
    /* list-style-type: armenian; */
}

.remove-scrollbar {
    scrollbar-width: 0 !important;
    scrollbar-gutter: 0 !important;
}
.remove-scrollbar::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

::-webkit-scrollbar-track {
    background: #e9e9e9;
}

::-webkit-scrollbar-thumb {
    background: #e43f83;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

::selection {
    background: #2eb6c0;
    color: #fff;
}

.css-1bpxe2k-MuiGrid-root>.MuiGrid-item {
    padding-left: 0 !important;
}

.css-12xbdue-MuiGrid-root>.MuiGrid-item {
    padding-left: 0 !important;
}